/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/axios@1.1.3/dist/axios.min.js
 * - /npm/store@2.0.12/dist/store.legacy.min.js
 * - /npm/dayjs@1.11.6/dayjs.min.js
 * - /npm/dayjs@1.11.6/plugin/utc.js
 * - /npm/dayjs@1.11.6/plugin/timezone.js
 * - /npm/dayjs@1.11.6/plugin/advancedFormat.js
 * - /npm/dayjs@1.11.6/plugin/customParseFormat.js
 * - /npm/dayjs@1.11.6/plugin/duration.js
 * - /npm/dayjs@1.11.6/plugin/isBetween.js
 * - /npm/dayjs@1.11.6/plugin/isoWeek.js
 * - /npm/dayjs@1.11.6/plugin/localizedFormat.js
 * - /npm/dayjs@1.11.6/locale/es.js
 * - /npm/qs@6.11.0/dist/qs.min.js
 * - /npm/react@18.2.0/umd/react.production.min.js
 * - /npm/react-dom@18.2.0/umd/react-dom.production.min.js
 * - /npm/react-intl@6.6.4/react-intl.iife.min.js
 * - /npm/react-is@18.2.0/umd/react-is.production.min.js
 * - /npm/react-redux@8.0.5/dist/react-redux.min.js
 * - /npm/react-router-dom@6.4.3/dist/umd/react-router-dom.production.min.js
 * - /npm/redux@4.2.0/dist/redux.min.js
 * - /npm/redux-logger@3.0.6/dist/redux-logger.min.js
 * - /npm/redux-persist@6.0.0/dist/redux-persist.min.js
 * - /npm/redux-thunk@2.4.2/dist/redux-thunk.min.js
 * - /npm/reselect@4.1.6/dist/reselect.min.js
 * - /npm/scriptjs@2.5.9/dist/script.min.js
 * - /npm/styled-components@5.3.5/dist/styled-components.min.js
 * - /npm/uuid@8.3.2/dist/umd/uuid.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
